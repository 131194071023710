import PassiveUser from '../index'

export default [
  {
    path: '/passive-user-error',
    name: 'PassiveUser',
    component: PassiveUser,
    beforeEnter: (to, from, next) => {
      if (from.name) {
        next();
      } else {
        next('/');
      }
    },
    meta: {
      slug: 'passiveUser',
      page: 'passiveUser'
    }
  }
]
